<!-- service has running build process and finished project core add -->
@if (!!state.buildProcess || !!state.deployProcess) {

  <!-- has core process -->
  @if (!!state.projectCoreProcess || state.buildProcess?.status === processStatuses.PENDING) {

    <zui-wrap class="__creating-project-wrap">

      <h2 class="fx:text-center  __project-core-process-wrap_title">
        Preparing project and running priority<br/> processes,
        your pipeline is in queue.
      </h2>

      <zui-processes-card
        [mode]="processItemModes.Full"
        [processItemTranslations]="state.processItemTranslations"
        [runningProcesses]="state.serviceProcessesMap?.running"
        [pendingProcesses]="state.serviceProcessesMap?.pending"
        [maxProcessesNumber]="10"
        [hideCancel]="true"
        [processCancelProgressesMap]="{}"
        [maxHeight]="'100%'"
        (pipelineDetailClicked)="foo = $event"
        (buildCancelClicked)="foo = $event">
      </zui-processes-card>

    </zui-wrap>

  } @else {

    @if (!!state.buildProcess && (!!state.buildProcess?.appVersion?.build || !!state.buildProcess?.appVersion?.prepareCustomRuntime)) {
      <z-pipeline-detail
        [baseKey]="featureName"
        [logsScrollHeight]="state.isCompact
          ? 'calc(100vh - 180px)'
          : 'calc(100vh - 100px)'"
        [mode]="defaultMode"
        (modeChanged)="defaultMode = $event"
        [appVersion]="state.buildProcess?.appVersion">
      </z-pipeline-detail>
    }

    @if (!!state.deployProcess) {
      <h5 class="__deploy-title">
        Running deploy
      </h5>

      <div class="__deploy-item">
        <zui-process-item
          [translations]="state.processItemTranslations"
          [data]="state.deployProcess">
        </zui-process-item>
      </div>
    }

  }

}

<!-- service doesn't have running build and project core add process -->
@if (!state.buildProcess && !state.deployProcess) {

  <div class="__intro">

    <h2 class="__intro_title">

      Ready to build, prepare,
      deploy <br/>and run your&nbsp;application

      on <br/>{{ state.serviceStack?.serviceStackTypeInfo.serviceStackTypeName }}

      service <strong>{{ state.serviceStack?.name }}</strong>

    </h2>

    <div class="__ng-content">
      <ng-content></ng-content>
    </div>

  </div>

  <zui-separator class="__intro-separator" [orientation]="'horizontal'" [size]="'large'" [spacing]="'large'"></zui-separator>

  <div class="__steps-grid">

    <!-- first step, add zerops.yml -->
    <div class="__step">

      <h3 class="__step_title">
        <zui-docs-text>
          Start by adding <a data-zui-docs="build/build-config.html">zerops.yml</a> to the root of your repository and modify it to fit your application
        </zui-docs-text>
      </h3>

      <zui-separator
        class="__step_title-separator"
        [position]="'center'"
        [size]="'medium'"
        [spacing]="'medium'">
      </zui-separator>

      <div class="__editor-wrap">

        <button
          class="__editor-wrap_copy"
          (click)="copyZeropsYamlToClipboard()"
          type="button"
          mat-icon-button>
          <mat-icon class="__editor-wrap_copy-icon">content_copy</mat-icon>
        </button>

        <zui-code-field
          class="__editor"
          [ngClass]="'__editor--' + state.serviceStack?.serviceStackTypeId"
          [editorModel]="{
            language: 'yaml',
            fileName: 'zerops-yml'
          }"
          [editorOptions]="{
            quickSuggestions: {
              other: true,
              comments: false,
              strings: true
            },
            folding: true,
            glyphMargin: false,
            lineNumbers: 'off'
          }"
          [(ngModel)]="userProjectZeropsYaml">
        </zui-code-field>

      </div>

    </div>

    <!-- seconds step, trigger pipeline -->
    <div class="__step">

      <h3 class="__step_title">
        <zui-docs-text>
          Then trigger the <a data-zui-docs="build/build-config.html#build-part-and-its-properties">build, prepare, deploy and run</a> pipeline you set up in zerops.yml
        </zui-docs-text>
      </h3>

      <zui-separator
        class="__step_title-separator"
        [position]="'center'"
        [size]="'medium'"
        [spacing]="'medium'">
      </zui-separator>

      <!-- trigger automatically section -->
      <div class="__pipeline-item">

        <h4 class="__pipeline-item_title"><strong>Automatically</strong>, with each commit or release, by connecting the service with your GitHub or&nbsp;GitLab repository</h4>

        <z-remote-repository-connector [serviceStackId]="state.serviceStack?.id"></z-remote-repository-connector>

      </div>

      <zui-separator
        class="__pipeline-item-separator"
        [position]="'center'"
        [spacing]="'none'"
        [size]="'large'"
        [orientation]="'horizontal'">
      </zui-separator>

      <!-- trigger manually section -->
      <div class="__pipeline-item">

        <h4 class="__pipeline-item_title"><strong>Manually</strong>, by using Zerops CLI to trigger the pipeline from your existing CI/CD pipeline or from your local terminal</h4>

        <zui-zcli-examples
          [project]="state.serviceStack?.project.name | nameQuoting"
          [service]="state.serviceStack?.name"
          [region]="region">
        </zui-zcli-examples>

      </div>

    </div>

  </div>

}
